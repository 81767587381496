import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../UserContext';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function AllUser() {
  const { userInfo } = useContext(UserContext);
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState(0);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch('https://homeworktips-22mg.onrender.com/moderator/all-users', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (response.ok) {
          const data = await response.json();
          setUsers(data.users);
          setAllUsers(data.totalUsers);
          console.log(data.users);
        } else {
          toast.error('Failed to fetch users');
          console.log(response.json());
        }
      } catch (error) {
        toast.error('Error fetching users: ' + error.message);
      }
    };

    fetchUsers();
  }, [userInfo]);

  const switchToMod = async (id) => {
    try {
      const response = await fetch(`https://homeworktips-22mg.onrender.com/moderator/user-to-mod/${id}`, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        toast.success(data.message || "User type changed successfully");
      } else {
        toast.error('Failed to change user type');
      }
    } catch (error) {
      toast.error('Error changing user type: ' + error.message);
    }
  };

  const switchToNormal = async (id) => {
    try {
      const response = await fetch(`https://homeworktips-22mg.onrender.com/moderator/user-to-normal/${id}`, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        toast.success(data.message || "User type changed successfully");
        window.location.href = "/users";
      } else {
        toast.error('Failed to change user type');
      }
    } catch (error) {
      toast.error('Error changing user type: ' + error.message);
    }
  };

  const suspendUser = async (id) => {
    try {
      const response = await fetch(`https://homeworktips-22mg.onrender.com/moderator/suspend-user/${id}`, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        toast.success(data.message || "User suspended successfully");
        window.location.href = "/users";
      } else {
        toast.error('Failed to suspend user');
      }
    } catch (error) {
      toast.error('Error suspending user: ' + error.message);
    }
  };

  const liftUserSuspension = async (id) => {
    try {
      const response = await fetch(`https://homeworktips-22mg.onrender.com/moderator/lift-suspension/${id}`, {
        method: "POST",
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        toast.success(data.message || "User suspension successfully lifted");
        window.location.href = "/users";
      } else {
        toast.error('Failed to lift user suspension');
      }
    } catch (error) {
      toast.error('Error lifting user suspension: ' + error.message);
    }
  };

  return (
    <div className="flex flex-col w-full items-center mt-[50px]">
      <h2 className='text-2xl font-semibold'>Manage All Registered Users</h2>
      <h2 className='text-xl mt-5'>Number of Registered Users: {allUsers}</h2>
      <div className="overflow-x-auto w-full mt-4">
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr className="border-b">
              <th className="px-4 py-2 text-left">Username</th>
              <th className="px-4 py-2 text-left">Balance</th>
              <th className="px-4 py-2 text-left">Email</th>
              <th className="px-4 py-2 text-left">Date Joined</th>
              <th className="px-4 py-2 text-left">Status</th>
              <th className="px-4 py-2 text-left">User Type</th>
              <th className="px-4 py-2 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.length === 0 ? (
              <tr>
                <td colSpan="7" className="px-4 py-2 text-center">No users found</td>
              </tr>
            ) : (
              users.map(user => (
                <tr key={user.user_id} className="border-b">
                  <td className="px-4 py-2">{user.name}</td>
                  <td className="px-4 py-2">${user.balance}</td>
                  <td className="px-4 py-2">{user.email}</td>
                  <td className="px-4 py-2">{user.createdAt}</td>
                  <td className="px-4 py-2">{user.accountStatus}</td>
                  <td className="px-4 py-2">{user.userType}</td>
                  <td className="px-4 py-2">
                    <Link to={`/author/${user.user_id}`} className='underline text-blue-600'>View Profile</Link>
                    {user.userType === "writer" ? (
                      <button onClick={() => switchToMod(user.user_id)} className='ml-2 bg-green-500 text-white px-2 py-1 rounded'>Turn to Moderator</button>
                    ) : (
                      <button onClick={() => switchToNormal(user.user_id)} className='ml-2 bg-yellow-500 text-white px-2 py-1 rounded'>Revert to Normal User</button>
                    )}
                    {user.accountStatus === "active" ? (
                      <button onClick={() => suspendUser(user.user_id)} className='ml-2 bg-red-500 text-white px-2 py-1 rounded'>Suspend User</button>
                    ) : (
                      <button onClick={() => liftUserSuspension(user.user_id)} className='ml-2 bg-blue-500 text-white px-2 py-1 rounded'>Lift Suspension</button>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </div>
  );
}

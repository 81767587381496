import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../UserContext';
// import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function AllUserEarnings() {
  const { userInfo } = useContext(UserContext);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch('https://homeworktips-22mg.onrender.com/moderator/all-users', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (response.ok) {
          const data = await response.json();
          setUsers(data.users);
          console.log(data.users);
        } else {
          toast.error('Failed to fetch users');
          console.log(response.json());
        }
      } catch (error) {
        toast.error('Error fetching users: ' + error.message);
      }
    };

    fetchUsers();
  }, [userInfo]);

  // const switchToMod = async (id) => {
  //   try {
  //     const response = await fetch(`https://homeworktips-22mg.onrender.com/moderator/user-to-mod/${id}`, {
  //       method: "POST",
  //       headers: {
  //         'Authorization': `Bearer ${localStorage.getItem('token')}`
  //       }
  //     });

  //     if (response.ok) {
  //       const data = await response.json();
  //       toast.success(data.message || "User type changed successfully");
  //     } else {
  //       toast.error('Failed to change user type');
  //     }
  //   } catch (error) {
  //     toast.error('Error changing user type: ' + error.message);
  //   }
  // };

  // const switchToNormal = async (id) => {
  //   try {
  //     const response = await fetch(`https://homeworktips-22mg.onrender.com/moderator/user-to-normal/${id}`, {
  //       method: "POST",
  //       headers: {
  //         'Authorization': `Bearer ${localStorage.getItem('token')}`
  //       }
  //     });

  //     if (response.ok) {
  //       const data = await response.json();
  //       toast.success(data.message || "User type changed successfully");
  //       window.location.href = "/users";
  //     } else {
  //       toast.error('Failed to change user type');
  //     }
  //   } catch (error) {
  //     toast.error('Error changing user type: ' + error.message);
  //   }
  // };

  // const suspendUser = async (id) => {
  //   try {
  //     const response = await fetch(`https://homeworktips-22mg.onrender.com/moderator/suspend-user/${id}`, {
  //       method: "POST",
  //       headers: {
  //         'Authorization': `Bearer ${localStorage.getItem('token')}`
  //       }
  //     });

  //     if (response.ok) {
  //       const data = await response.json();
  //       toast.success(data.message || "User suspended successfully");
  //       window.location.href = "/users";
  //     } else {
  //       toast.error('Failed to suspend user');
  //     }
  //   } catch (error) {
  //     toast.error('Error suspending user: ' + error.message);
  //   }
  // };

  // const liftUserSuspension = async (id) => {
  //   try {
  //     const response = await fetch(`https://homeworktips-22mg.onrender.com/moderator/lift-suspension/${id}`, {
  //       method: "POST",
  //       headers: {
  //         'Authorization': `Bearer ${localStorage.getItem('token')}`
  //       }
  //     });

  //     if (response.ok) {
  //       const data = await response.json();
  //       toast.success(data.message || "User suspension successfully lifted");
  //       window.location.href = "/users";
  //     } else {
  //       toast.error('Failed to lift user suspension');
  //     }
  //   } catch (error) {
  //     toast.error('Error lifting user suspension: ' + error.message);
  //   }
  // };

  return (
    <div className="flex flex-col w-full items-center mt-[50px]">
      <h2 className='text-2xl font-semibold'>Manage All Users Earnings</h2>
      <div className="overflow-x-auto w-full mt-4">
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr className="border-b">
              <th className="px-4 py-2 text-left">Username</th>
              <th className="px-4 py-2 text-left">Balance</th>
              <th className="px-4 py-2 text-left">Status</th>
            </tr>
          </thead>
          <tbody>
            {users.length === 0 ? (
              <tr>
                <td colSpan="7" className="px-4 py-2 text-center">No users found</td>
              </tr>
            ) : (
              users.map(user => (
                <tr key={user.user_id} className="border-b">
                  <td className="px-4 py-2">{user.name}</td>
                  <td className="px-4 py-2">${user.balance}</td>
                  <td className="px-4 py-2">{user.accountStatus}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <ToastContainer />
    </div>
  );
}

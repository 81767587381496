export default function Footer() {
  


  return (
    <div className="w-full flex flex-col justify-center items-center py-10 text-center">
    <p>Copyright 2024 All Rights Reserved by Homeworktips</p>
    </div>
  );
}

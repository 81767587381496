import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../UserContext';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function AllUserPosts() {
  const { userInfo } = useContext(UserContext);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPosts = async () => {
      setLoading(true);
      try {
        const response = await fetch('https://homeworktips-22mg.onrender.com/moderator/all-posts', {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        if (response.ok) {
          const data = await response.json();
          setPosts(data);
        } else {
          toast.error('Failed to fetch posts');
        }
      } catch (error) {
        toast.error('Error fetching posts: ' + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, [userInfo]);

  const deleteAllPost = async () => {
    try {
      const response = await fetch('https://homeworktips-22mg.onrender.com/moderator/delete-post', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        method: "DELETE"
      });

      if (response.ok) {
        const data = await response.json();
        toast.success(data.message || "All posts successfully deleted by moderator");
        setPosts([]); // Clear the posts list
      } else {
        toast.error('Failed to delete all posts');
      }
    } catch (error) {
      toast.error("Error deleting all posts: " + error.message);
      console.log(error);
    }
  };

  const deletePost = async (slug) => {
    try {
      const response = await fetch(`https://homeworktips-22mg.onrender.com/moderator/post/${slug}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        method: "DELETE"
      });

      if (response.ok) {
        const data = await response.json();
        toast.success(data.message || "Post successfully deleted by moderator");
        setPosts(posts.filter(post => post.slug !== slug)); // Remove the deleted post from the state
      } else {
        const errorData = await response.json();
        toast.error('Failed to delete post: ' + errorData.error);
      }
    } catch (error) {
      toast.error("Error deleting post: " + error.message);
      console.log(error);
    }
  };

  const approvePost = async (slug) => {
    try {
      const response = await fetch(`https://homeworktips-22mg.onrender.com/moderator/approve-post/${slug}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        method: "POST"
      });

      if (response.ok) {
        const data = await response.json();
        toast.success(data.message || "Post successfully approved by moderator");
      } else {
        const errorData = await response.json();
        toast.error('Failed to approve post: ' + errorData.error);
      }
    } catch (error) {
      toast.error("Error approving post: " + error.message);
      console.log(error);
    }
  };

  if (loading) {
    return <div className="flex flex-col w-full items-center mt-[50px]">Loading...</div>;
  }

  return (
    <div className="flex flex-col w-full items-center mt-[50px]">
      <h2 className='text-2xl font-semibold'>Manage All User Posts</h2>
      <div className="overflow-x-auto w-full mt-4">
        <table className="min-w-full bg-white border border-gray-300">
          <thead>
            <tr className="border-b">
              <th className="px-4 py-2 text-left">Title</th>
              <th className="px-4 py-2 text-left">Views</th>
              <th className="px-4 py-2 text-left">Reward</th>
              <th className="px-4 py-2 text-left">Comments</th>
              <th className="px-4 py-2 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {posts.length === 0 ? (
              <tr>
                <td colSpan="5" className="px-4 py-2 text-center">No posts found.</td>
              </tr>
            ) : (
              posts.map(post => (
                <tr key={post._id} className="border-b">
                  <td className="px-4 py-2">{post.title}</td>
                  <td className="px-4 py-2">{post.views}</td>
                  <td className="px-4 py-2">${post.reward.toFixed(2)}</td>
                  <td className="px-4 py-2">{post.commentCount}</td>
                  <td className="px-4 py-2">
                    <Link to={`/${post.slug}`} className='underline text-blue-600 mr-5'>View Post</Link>
                  {
                    post.status === "approved" ? (
                    <button onClick={() => deletePost(post.slug)} className='bg-red-500 text-white px-2 py-1 rounded'>Delete Post</button>
                  
                    ):(
                      <>
                      <button onClick={() => approvePost(post.slug)} className='bg-green-500 text-white px-2 py-1 rounded mr-2'>Approve Post</button>
                    <button onClick={() => deletePost(post.slug)} className='bg-red-500 text-white px-2 py-1 rounded'>Delete Post</button>
                  
                      </>
                    )
                  }
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="mt-4">
        <button onClick={deleteAllPost} className='bg-red-500 text-white px-4 py-2 rounded'>Delete All Posts</button>
      </div>
      <ToastContainer />
    </div>
  );
}
